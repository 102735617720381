import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { context, ACTION_TYPE } from "../../State";
import inviteUserIcon from "../../img/icon-invite-user.svg"
import viewDetailsIcon from "../../img/icon-view-details.svg"
import editIcon from "../../img/icon-edit.svg"
import deleteIcon from "../../img/icon-delete.svg"
import createIcon from "../../img/azure_signet.png"
import { useRef } from "react";
import Group from '../../components/Group'
import { log, tryLocalStorageGetItem, tryLocalStorageSetItem } from "../../helpers/utils";
import { Button, InverseButton } from "../../components/CustomUI";
import PersonIcon from '@mui/icons-material/Person';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadIcon from '@mui/icons-material/Upload';
import CircleIcon from '@mui/icons-material/Circle';
import { DragDropContext } from "react-beautiful-dnd";
import SelectItemList from "../../components/SelectListMenu";
import { useEffect } from "react";
import Api from "../../helpers/service";
import GroupObj from "../../model/Group";
import DialogTable from "../../components/DialogTable";
import CircularProgress from '@mui/material/CircularProgress';
import CustomSnackbar from "../../components/Snackbar";
import CustomDialog from '../../components/DialogText';
import IntegrationView from '../../components/IntegrationView';
import { CustomTooltip } from '../../components/CustomUI';
import AddIcon from '@mui/icons-material/Add';
import { AdminIcon } from '../../img/addmin-icon';
import SearchIcon from '@mui/icons-material/Search';
import DialogTableSearchUsers from '../../components/DialogTableSearchUsers';
import CompleteRegistrationDialog from '../../components/CompleteRegistrationDialog';


export default function IntegrationsPage(props) {
    const { text } = props;
    const [showSnack, setShowSnack] = React.useState(true);
    const [isShown, setIsShown] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAdminDialog, setOpenAdminDialog] = React.useState(false);
    const [didLoad, setDidLoad] = React.useState(false);
    const [hasIntegrations, setHasIntegrations] = React.useState(false);
    const [groups, setGroups] = React.useState([]);
    const [load, setLoad] = React.useState(false);
    const [openSearchUserDialog, setOpenSearchUserDialog] = React.useState(false);
    const { t } = useTranslation();
    const { state, dispatch } = React.useContext(context);
    const [adminEmail, setAdminEmail] = React.useState(state.userData.email);
    const groupRef = useRef([]);
    const api = new Api();

    const handleCloseSnackBar = () => {
        setShowSnack(false);
    };

    const handleClose = (index, event) => {
        setIsShown(false);
    };

    useEffect(
        () => {
            loadIntegrations();
            if (tryLocalStorageGetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK) === 'true') {
                dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, payload: true });
                tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, false);
            }

            if (tryLocalStorageGetItem(ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK) === 'true') {
                dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, payload: true });
                tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, false);
            }
        },
        []
    );
    function loadIntegrations() {
        api.getIntegrationsByOwner().then(function (res) {
            let groupArray = [];
            Object.entries(res).map(group => {
                const owner = group[0];
                const integrations = group[1];
                let groupObj = createGroup(owner, integrations, false);
                groupArray.push(groupObj)
            });
            //TODO change this when integration groups are implemented
            let notSharedGroupArray = groupArray.filter(function (group) {
                return group.shared === false;
            });
            if (notSharedGroupArray.length === 0) {
                let groupDefault = createGroup('Me', [], true);
                groupArray.unshift(groupDefault);
            }
            setGroups(...groups, groupArray);
            setDidLoad(true);
            setHasIntegrations(groupArray.length > 0);
        }).catch(function (error) {
            log(error);
        })
    }

    function createGroup(owner, integrations, isDefaultGroup) {
        let isShared = isDefaultGroup === false ? integrations[0].shared : false;
        let group = new GroupObj();
        group.name = isShared ? "Shared group" : "My group";
        group.owner = isShared ? owner : 'Me';
        group.shared = isShared;
        group.color = isShared ? "#F4F5F4" : "#EAEFF8";
        group.integrations = integrations.sort(function (x, y) {
            return x.creationTimestamp - y.creationTimestamp;
        });
        group.dragAndDrop = false;
        return group;
    }

    const goToAdd = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_ADDED_SNACK, payload: false });
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, false);
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, payload: false });
        window.location.assign("#/addIntegration");
    }

    // define group actions
    const groupActions = (id) => (
        < >
            <CustomTooltip title={t('tooltip.delete_group')} placement='top'>
                <DeleteForeverIcon style={{ webkitMaskImage: '-webkit-radial-gradient(white, black)' }}
                    sx={{ marginRight: '20px', display: 'none', '&:hover': { backgroundColor: '#FFFFF7', borderRadius: 18 } }}
                    onClick={(e) => deleteGroup(id, e)}
                />
            </CustomTooltip>

            <CustomTooltip title={t('tooltip.transfer_group')} placement='top'>
                <UploadIcon style={{ webkitMaskImage: '-webkit-radial-gradient(white, black)' }}
                    sx={{ marginRight: '20px', display: 'none', '&:hover': { backgroundColor: '#FFFFF7', borderRadius: 18 } }}
                    onClick={(e) => shareGroup(id, e)}
                />
            </CustomTooltip>

            <CustomTooltip title={t('tooltip.manage_admins')} placement='top'>
                    <AdminIcon style = {{webkitMaskImage: '-webkit-radial-gradient(white, black)', borderRadius:'50%' }}
                        onClick={(e) => manageGroupAdmin(id, e)}
                        onMouseOver = {(e) => e.target.style.background = '#FFFFF7'}
                        onMouseLeave = {(e) => e.target.style.background = 'transparent'}
                    />
            </CustomTooltip>
            <Button
                sx={{ height: '5vh', width: '11.2vw', marginLeft: '20px',marginBottom:'0px',marginTop:'0px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                onClick={goToAdd}
            >
                <AddIcon sx={{ maxWidth: '2.5vw', maxHeight: '2.5vh' }} />
                <Typography variant='h4' sx={{ fontSize: '1.2vw' }} style={{ font: 'normal normal Source Sans Pro', marginLeft: '0.2vw', paddingRight: '0.5vw' }}>
                    Add integration
                </Typography>

            </Button>
        </>
    );

    const deleteGroup = (groupId, event) => {
        event.stopPropagation();
        if (groups[groupId].tableRows.length > 0) {
            alert("Cannot delete group with integrations in it!")
        } else {
            const newState = [...groups];
            newState.splice(groupId, 1);
            setGroups(newState);
        }
    };

    const shareGroup = (groupId, event) => {
        event.stopPropagation();
    };

    const manageGroupAdmin = (groupId, event) => {
        event.stopPropagation();
        setOpenAdminDialog(true)
    };

    const handleSearchUser = (event) => {
        event.stopPropagation();
        setOpenSearchUserDialog(true);
    }

    const addIntegrationToGroup = (groupId, event) => {
        event.stopPropagation();
        // get the last id in all groups
        let rows = [];
        {
            groups.map((group, index) => (
                rows.push(...group.tableRows)
            ))
        }
        rows = rows.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        let lastIndex = parseInt(rows[rows.length - 1].id) + 1;

        let newIntegration = createIntegration(`${lastIndex}`, `Integration-${lastIndex}`, "getidee.de", "Trial: 12days left", "-", "1", "on", "4 mins ago", <CircleIcon style={{ color: lastIndex % 2 === 0 ? "cyan" : "yellow" }} />, false)
        const newState = [...groups];
        newState[groupId].tableRows.push(newIntegration);
        setGroups(newState);
    };

    // end group actions

    function createIntegration(id, name, domain, licenceType, lUsers, eUsers, scim, scimStatus, branding, isTrialExpired) {
        return { id, name, domain, licenceType, lUsers, eUsers, scim, scimStatus, branding, isTrialExpired };
    }

    const getMockIntegrations = (count, offset = 0) =>
        Array.from({ length: count }, (v, k) => k).map(k => (
            createIntegration(`${k + offset}`, `Integration-${k + offset}`, "getidee.de", "Trial: 12days left", "-", "1", "on", "4 mins ago", <CircleIcon style={{ color: k % 2 === 0 ? "cyan" : "yellow" }} />, false)
        )
        );
    log("Integration data");
    log(state.integrationData);
    let isActiveContractForAnIntegration = (state.integrationData.licenseType === "Licensed") && (state.integrationData.startDate <  Date.now()) && (state.integrationData.expirationDate) > new Date(Date.now()); 
    let rowActionMenuItems = [
        //{text: 'Sign In', imageUrl: inviteUserIcon, type: "integration", func: signIn}
        //{text: t('select_menu.delete_user'), imageUrl: deleteIcon, isDisabled:"true", type:"user"}
    ];
    var protocol = state.integrationData.integrationType === "OIDC" ? "OIDC" : (state.integrationData.integrationType === "MICROSOFT" ? "WS-Fed" : "SAML");
    if (protocol !== "OIDC" && !(protocol === "SAML" && !state.integrationData.domainVerified)) {
        rowActionMenuItems.push({ text: t('select_menu.invite_user'), imageUrl: inviteUserIcon, func: handleOpenDialogInviteUser, isDisabled: (!state.integrationData.domainVerified || ((state.integrationData.authenticationSettingsDto.CLIENT_CERTIFICATE_SD_ENABLED || state.integrationData.authenticationSettingsDto.CLIENT_CERTIFICATE_MD_ENABLED) && !state.integrationData.authenticationSettingsDto.CLIENT_CERTIFICATE_BYOD_ALLOWED)) ? "true" : "false", type: "user" })
        rowActionMenuItems.push({ text: t('select_menu.create_azure_user'), imageUrl: createIcon, isDisabled: (state.integrationData.integrationType == "MICROSOFT") ? "false" : "true", type: "user", func: handleCreateAzureUser })
    }
    rowActionMenuItems.push({ text: t('select_menu.view_details'), imageUrl: viewDetailsIcon, isDisabled: "false", type: "integration", func: handleOpenViewDetailsIntegration })
    rowActionMenuItems.push({ text: t('select_menu.edit'), imageUrl: editIcon, isDisabled: state.integrationData.isShared ? "true" : "false", type: "integration", func: handleOpenEditIntegration })

    const handleSnackAddedIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_ADDED_SNACK, payload: false });
    }

    const handleSnackChangedIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, payload: false });
    }

    const handleSnackAddedOIDCIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_ADDED_OIDC_SNACK, payload: false });
    }

    const handleSnackCreateAzureUserClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_CREATE_AZURE_USER_SNACK, payload: false });
    }

    const handleSnackDeletedIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, false);
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, payload: false });
    }

    const handleSnackDeletedDeviceClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_DEVICE_DELETED_SNACK, false);
        dispatch({ type: ACTION_TYPE.SHOW_DEVICE_DELETED_SNACK, payload: false });
    }

    const handleSnackDeletedUserClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_USER_DELETED_SNACK, false);
        dispatch({ type: ACTION_TYPE.SHOW_USER_DELETED_SNACK, payload: false });
    }

    function handleOpenDialogInviteUser(index, event) {
        setIsShown({ [index]: false });
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_INVITE_NEW_USER, payload: true });
    }

    function handleOpenDialogDeleteIntegration(index, event) {
        setIsShown({ [index]: false });
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: true })
    }

    function handleCreateAzureUser(){
        window.location.assign("#/createAzureUser");
    }

    function handleOpenViewDetailsIntegration(index, event) {
        handleClose();
        handleCloseDialogEditIntegration();
        log("Integration data ...");
        log(state.integrationData);
        dispatch({ type: ACTION_TYPE.OPEN_VIEW_DETAILS_INTEGRATION, payload: true });
    }

    function handleOpenEditIntegration(index, event) {
        handleClose();
        handleCloseDialogViewDetailsIntegration(index, event);
        log("Integration data ...");
        log(state.integrationData);
        dispatch({ type: ACTION_TYPE.OPEN_EDIT_INTEGRATION, payload: true });
    }

    function handleCloseDialogViewDetailsIntegration(index, event) {
        dispatch({ type: ACTION_TYPE.OPEN_VIEW_DETAILS_INTEGRATION, payload: false });
    }

    function handleCloseDialogEditIntegration(index, event) {
        dispatch({ type: ACTION_TYPE.OPEN_EDIT_INTEGRATION, payload: false });
    }



    const handleRowInfoClicked = (index, event, rowId, tableId) => {
        setIsShown(true);
        setAnchorEl(event.currentTarget);

        let integration = groups[tableId].integrations.find(integration => integration.id === rowId);
        log("Integration logs....");
        log(integration);
        if (integration) {
            //decide about login method, fallback login method, number of devices
            let loginMethod = "Web-AuthN";
            let fallbackMethod = "none";
            let numberOfDevices = "Multi-device";
            if (integration.authenticationSettingsDto.AUTHN_ENABLED) {
                loginMethod = "AuthN";
                numberOfDevices = "Multi-device";
            } else if (integration.authenticationSettingsDto.WEBAUTHN_ENABLED) {
                if (integration.authenticationSettingsDto.CMLA_ENABLED) {
                    fallbackMethod = "Secure Magic-Link";
                }
            } else if (integration.authenticationSettingsDto.CMLA_ENABLED) {
                loginMethod = "Secure Magic-Link";
                numberOfDevices = "none";
            } else if (integration.authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED) {
                loginMethod = "Web-AuthN + Access Key"
                numberOfDevices = "Multi-device"
            } else {
                loginMethod = "none";
                fallbackMethod = "none";
                numberOfDevices = "none";
            }
            dispatch({
                type: ACTION_TYPE.INTEGRATION_DATA,
                payload: {
                    domain: integration.domain,
                    integrationId: integration.id,
                    integrationType: integration.clientType !== null ? integration.clientType : integration.integrationType,
                    siteId: integration.siteId,
                    clientMetadataId: integration.clientMetadataId,
                    integrationName: integration.integrationName,
                    loginMethod: loginMethod,
                    fallbackMethod: fallbackMethod,
                    numberOfDevices: numberOfDevices,
                    metadataUrl: integration.metadataUrl,
                    clientMetadataId: integration.clientMetadataId,
                    authenticationSettingsDto: integration.authenticationSettingsDto,
                    isShared: integration.shared,
                    numberOfSyncedUsers: integration.numberOfSyncedUsers,
                    scimEndpointUrl: integration.scimEndpointUrl,
                    scimEnabled: integration.scimEnabled,
                    scimSecret: integration.scimSecret,
                    licenseType: integration.licenseType,
                    expirationDate: integration.expirationDate,
                    startDate: integration.startDate,
                    clientId: integration.clientId,
                    siemEnabled: integration.siemEnabled,
                    workspaceId: integration.workspaceId,
                    serverHostname: integration.serverHostname,
                    serverPort: integration.serverPort,
                    siemType: integration.siemType,
                    serverName: integration.serverName,
                    domainVerified: integration.domainVerified
                }
            });
            dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: integration.authenticationSettingsDto });
        }
    };

    function handleDialogIntegrationDelete() {
        log("Integration to delete: ");
        log(state.integrationData);
        switch (state.integrationData.integrationType) {
            case "MICROSOFT":
            case "SALESFORCE":
            case "OKTA":
            case "ZENDESK":
            case "AWS":
            case "TEAMVIEWER":
                api.deleteIntegration(state.integrationData.integrationId).then(function (res) {
                    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                    tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                    window.location.reload();
                }).catch(function (error) {
                    log(error);
                    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                });
                break;
            case "CUSTOM":
                if (state.integrationData.clientMetadataId === null) {
                    api.deleteUrl(state.integrationData.integrationId).then(function (res) {
                        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                        window.location.reload();
                    }).catch(function (error) {
                        log(error);
                        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                    });
                } else {
                    api.deleteIntegration(state.integrationData.integrationId).then(function (res) {
                        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                        window.location.reload();
                    }).catch(function (error) {
                        log(error);
                        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                    });
                }
                break;
            case "GSUITE":
                api.deletegSuiteClientandSDKConnection(state.integrationData.siteId).then(function (res) {
                    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                    tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                    window.location.reload();
                }).catch(function (error) {
                    log(error);
                    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                });
                break;
            case "OIDC":
                api.deleteOIDCIntegration(state.integrationData.integrationId).then(function (res) {
                    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                    tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                    window.location.reload();
                }).catch(function (error) {
                    log(error);
                    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false });
                });


        }
    }

    // end group content

    // drag and drop helper methods

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        let destClone = Array.from(destination);

        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the table
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        log(`dragEnd ${result.source.index} to  ${result.destination.index}`);

        if (sInd === dInd) { // same table
            const items = reorder(groups[sInd].integrations, source.index, destination.index);
            const newState = [...groups];
            newState[sInd].integrations = items;
            setGroups(newState);
        } else { // move between tables
            const result = move(groups[sInd].integrations, groups[dInd].integrations, source, destination);
            const newState = [...groups];
            newState[sInd].integrations = result[sInd];
            newState[dInd].integrations = result[dInd];
            setGroups(newState);
        }
    };

    // end drag and drop helper methods

    const NoIntegrationView = (
        <Box sx={{ width: '90%', padding: '150px 50px', textAlign: 'center', border: '2px dashed #D6D6D6', borderRadius: '36px', marginTop: '35px' }}>
            <Typography variant="h3" component="div" gutterBottom sx={{ maxWidth: '420px', margin: '5px auto', fontSize: '19px' }}>
                Seems like you haven’t added an integration, yet. Add your first one in just 10 minutes.
            </Typography>
            <Button
                sx={{ maxWidth: 140, margin: '15px' }}
                onClick={goToAdd}
            >
                + Add integration
            </Button>
        </Box>
    )

    return (
        <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Typography style={{ marginLeft: '3vw', paddingLeft: '5vw', paddingBottom: '4vh' }} variant="h1" component="div">
                Integrations
            </Typography>
            <InverseButton 
                style={{marginLeft:'73vw', borderColor: 'transparent', color:'#000000', boxShadow: "0px 3px 6px #00000029",}}
                onClick={handleSearchUser} id="searchUserButton"
            >
                {t('dialog.search_user_button')}
                <SearchIcon sx={{marginLeft:'10px'}}/>
            </InverseButton>
            {didLoad && hasIntegrations &&
                <div style={{ marginLeft: '3vw', paddingLeft: '2vw' }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {groups.map((group, index) => (
                            <Box sx={{ marginTop: '3vh' }} key={index}>
                                <Group groupName={group.name}
                                    groupDescription={"Owner:" + group.owner}
                                    groupActions={group.shared ? '' : groupActions(index)}
                                    groupColor={group.color}
                                    tableHeaders={group.tableHeaders}
                                    tableRows={group.tableRows}
                                    rowInfoClicked={handleRowInfoClicked}
                                    dragAndDrop={group.dragAndDrop}
                                    id={`${index}`}
                                    ref={el => groupRef.current[index] = el} />
                            </Box>
                        ))}
                    </DragDropContext>
                    <SelectItemList open={Boolean(isShown)} onClose={handleClose}
                        items={rowActionMenuItems}
                        anchorEl={anchorEl} />
                    <DialogTable id="manageAdminAccessDialog" open={openAdminDialog} onCancel={setOpenAdminDialog} title={t('admin_access.heading_admin_access')} />
                    <DialogTableSearchUsers id="searchUsersDialog" open={openSearchUserDialog} onCancel={setOpenSearchUserDialog}/>
                    <IntegrationView onClose={handleCloseDialogViewDetailsIntegration} />
                    <IntegrationView onClose={handleCloseDialogEditIntegration} isEditModeOn={true} />
                </div>
            }
            {didLoad && !hasIntegrations && NoIntegrationView}
            {!didLoad &&
                <CircularProgress color="primary" sx={{ position: 'absolute', top: '50%', left: '50%' }} />
            }
            <CustomSnackbar open={state.showIntegrationAddedSnack} onCancel={handleSnackAddedIntegrationClose} type={'info'} vertical={'bottom'} horizontal={'right'} text={t('notification.info_added_integration')} buttonText={t('notification.okay')} showIcon='false' />
            <CustomSnackbar open={state.showIntegrationChangedSnack} onCancel={handleSnackChangedIntegrationClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.changes_saved')} buttonText={t('notification.okay')} showIcon='true' />
            <CustomSnackbar open={state.showIntegrationDeletedSnack} onCancel={handleSnackDeletedIntegrationClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.deleted_integration')} buttonText={t('notification.okay')} showIcon='true' />
            <CustomSnackbar open={state.showIntegrationAddedOIDCSnack} onCancel={handleSnackAddedOIDCIntegrationClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.info_added_oidc_integration')} buttonText={t('notification.okay')} showIcon='true'  />
            <CustomSnackbar open={state.showCreateAzureUserSnack} onCancel={handleSnackCreateAzureUserClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={state.createdAzureUserLink} buttonText={t('notification.okay')} showIcon='true' />
            <CustomSnackbar open={state.showDeviceDeletedSnack} onCancel={handleSnackDeletedDeviceClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.device_deleted')} buttonText={t('notification.okay')} showIcon='true' />
            <CustomSnackbar open={state.showUserDeletedSnack} onCancel={handleSnackDeletedUserClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.user_deleted')} buttonText={t('notification.okay')} showIcon='true' />
            <CustomDialog
                id = "deleteIntegrationDialog"
                open={state.isOpenedDialogDeleteIntegration}
                onConfirm={handleDialogIntegrationDelete}
                title={t('dialog.heading_delete_integration')}
                subtitle={t('dialog.text_delete_integration')}
                confirmBtnText={t('dialog.button_confirm_delete_integration')}
                cancelBtnText={t('dialog.button_cancel')}
                isCentered={true} />
        </Box>
    );
}
