import React, {useEffect} from 'react';
import AppBar from "../../components/AppBar";
import Menu from "../../components/VerticalMenu";
import CssBaseline from '@mui/material/CssBaseline';
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import { useTheme } from '@mui/styles';
import {Route, Redirect, withRouter, useLocation, Switch, useParams, Link} from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import GridViewIcon from '@mui/icons-material/GridView';
import LabelIcon from '@mui/icons-material/Label';
import IntegrationsPage from "./IntegrationsPage";
import {ACTION_TYPE, context} from "../../State";
import SettingsPage from "./SettingsPage";
import BrandingPage from "./BrandingPage";
import {useTranslation} from "react-i18next";
import CustomDialogTextBox from '../../components/DialogTextBox';
import CustomDialog from '../../components/DialogText';
import downloadIcon from '../../img/download.svg'
import thumbIcon from '../../img/icon-thumb.svg';
import Api from "../../helpers/service";
import { useIdleTimer } from 'react-idle-timer'
import {log, tryLocalStorageSetItem} from "../../helpers/utils";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import {SvgIcon, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import SvgComponent from '../../img/4-rounded-squares-component';
import { configManager } from '../../App';
import { ReviewButton } from '../../components/CustomUI';
import g2Icon from '../../img/g2icon.svg'
import { tryLocalStorageGetItem } from '../../helpers/utils';

const menuItems = [
    {
        title: "Integrations",
        path: "/integrations",
        icon: <SvgComponent size={'4vh'}/>,
        layout: <IntegrationsPage/>
    },
    /*{
        title: "Branding",
        path: "/branding",
        icon: <LabelIcon />,
        layout: <BrandingPage/>
    },*/
    {
        title: "Settings",
        path: "/settings",
        icon: <SettingsIcon style={{height:'4vh', width: '4vw'}} />,
        layout: <SettingsPage/>
    },
];

function getMenuItemByPath(path) {
    let item = menuItems.find((item) => {
        return item.path === path
    });
    if (item === undefined) { return menuItems[0]}
    return item;
}

function isValidPath(path) {
    return menuItems.find((item) => {
        return item.path === path
    }) !== undefined;
}

let lastSessionRefresh = Date.now();

// Broadcast that session is refreshed to other browser tabs
tryLocalStorageSetItem("lastSessionRefresh", lastSessionRefresh);

const MainLayout = (props) => {

    const {state, dispatch} = React.useContext(context);
    const { email } = props;
    const theme = useTheme();
    const location = useLocation();
    const [selectedLocationTitle, setSelectedLocationTitle] = React.useState(getMenuItemByPath(location.pathname).title);
    const [successSendMailToSales, setSuccessSendMailToSales] = React.useState(false);
    const { t, i18n } = useTranslation();
    const api = new Api();
    const sessionRefreshInterval = 1000 * 60 * 5; // 5min in ms
    const idleInterval = 1000 * 60 * 10; // 10min in ms
    const [idleTimerTimeout, setIdleTimerTimeout] = React.useState(idleInterval);
    const [refreshSessionDialogProgress, setRefreshSessionDialogProgress] = React.useState(0);
    const sessionRemainingTime = 1000 * 60 * 5; // 5min in ms
    const progressUpdateInterval = 1000; // in ms

    const checkSalesforce = () => {
        let email = tryLocalStorageGetItem("email", "");
        var defaultValue = new Object();
        defaultValue.user = "";
        defaultValue.filled = false;
        let registrationFormFilled = JSON.parse(tryLocalStorageGetItem("registrationFormFilled", JSON.stringify(defaultValue)));
        let registeredAdmin = registrationFormFilled["user"];
        let isAlreadyFilled = registrationFormFilled["filled"];
        dispatch({type: ACTION_TYPE.REGISTRATION_FORM_FILLED, payload: (registeredAdmin === email && isAlreadyFilled)});

    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (getRemainingTime() === 0 ) {
                setRefreshSessionDialogProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        onLogout();
                        return 0;
                    }
                    const diff = 100 / (sessionRemainingTime / progressUpdateInterval);
                    let progress = Math.min(oldProgress + diff, 100);

                    // Broadcast the change to other browser tabs
                    tryLocalStorageSetItem("refreshSessionDialogProgress", progress);

                    return progress;
                });
            }
        }, progressUpdateInterval);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(
        () => {
            setSelectedLocationTitle(getMenuItemByPath(location.pathname).title)
        },
        [location.pathname]
    );

    const onIdle = () => {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload:true});
    };

    const onAction = (event) => {
        setIdleTimerTimeout(getRemainingTime()); // update timer interval to remaining time
        if (Date.now() - lastSessionRefresh >= sessionRefreshInterval && !state.isOpenedDialogRefreshSession) {
            refreshSession();
        }
    };

    const {
        getRemainingTime,
    } = useIdleTimer({
        onIdle,
        onAction,
        timeout: idleTimerTimeout,
        promptTimeout: 0,
        events: [
            'keydown',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: true,
        syncTimers: 0
    });

    // Listen to LocalStorage events
    window.addEventListener('storage', (e) => {
        if (e.key == "lastSessionRefresh") {
            let val = parseInt(e.newValue);
            if (val != NaN) {
                lastSessionRefresh = val;
                dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload: false});
                setIdleTimerTimeout(idleInterval);
            }
        } else if (e.key == "refreshSessionDialogProgress") {
            let progress = parseFloat(e.newValue);
            if (progress != NaN) {
                if (refreshSessionDialogProgress < progress) { // Update progress only if it is larger than current
                    setRefreshSessionDialogProgress(progress);
                }
            }
        }
    });

    function onLogout() {
        api.logout().then(function (res) {
            log(res);
            dispatch({type: ACTION_TYPE.AUTH_USER , payload:false});
            dispatch({
                type: ACTION_TYPE.USER_DATA,
                payload: {
                    email: ""
                }
            });
            window.location.reload(false);
        }).catch(function (error) {
            log(error);
        });
    }

    function refreshSession() {
        setRefreshSessionDialogProgress(0); // reset progress bar
        api.refreshSession().then(function (res) {
            log(res);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload: false});
            setIdleTimerTimeout(idleInterval);
            lastSessionRefresh = Date.now();
            // Broadcast the change to other browser tabs
            tryLocalStorageSetItem("lastSessionRefresh", lastSessionRefresh);
        }).catch(function (error) {
            log(error);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload: false});
            setIdleTimerTimeout(idleInterval);
        });
    }

    function sendMailToSales() {
        api.sendMailToSales(false, null, null, null).then(function(res) {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_UPGRADE_PLAN, payload:false});
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:true});
            
        }).catch(function (error) {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:false});
        });
    }

    function handleCloseSuccessSendMail() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:false});    
    }

    function handleCloseDialogAccountCheckFailure() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE, payload:false});    
    }

    function handleCloseDialogAccountAccessRevoke() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE, payload:false});
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:true});    
    }

    function handleDialogAccountDelete() {
        api.deleteAccount().then(function(res) {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:false});
            dispatch({type: ACTION_TYPE.AUTH_USER , payload:false});
            //TODO stop session timer and redirect to registration page
            
        }).catch(function (error) {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:false});
        });
    }

    function handleDialogDeviceDelete() {
        log("Handle dialog device delete ...");
        log("Device data: " + state.deviceData);
        api.deleteDevice(state.deviceData).then(function(res) {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE, payload:false});
            dispatch({ type: ACTION_TYPE.SHOW_DEVICE_DELETED_SNACK, payload: true });
            window.location.reload();
        }).catch(function (error) {
            log(error);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE, payload:false});
        })
    }

    function handleDialogUserDelete() {
        log("Handle dialog delete user ...");
        log("User data: " + state.userDataEmail);
        api.deleteUser(state.userDataEmail).then(function(res) {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_USER, payload: false});
            dispatch({ type: ACTION_TYPE.SHOW_USER_DELETED_SNACK, payload: true });
            window.location.reload();
        }).catch(function (error) {
            log(error);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_USER, payload:false});
        })
    }

    function downloadReport() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DOWNLOAD_REPORT, payload:false});
        api.downloadReport(); 
    }

    function openReview() {
        let url = "/portal/user/g2";
        window.open(url, '_blank');
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth={false} disableGutters sx={{paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2)}}>
                <AppBar title={selectedLocationTitle} email={email} onLogout={onLogout}/>
                <div style={{display:'flex'}}>
                <Box sx={{ paddingTop:'7vh', paddingLeft: '2%', width: '5%', textAlign:'center'}} >
                    <Menu items={menuItems}/>
                    <ReviewButton onClick={openReview}> 
                                <img src={g2Icon} style={{
                                    display: 'flex',
                                    width: '24px',
                                    height: '24px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} />
                                <span style={{
                                    textAlign: 'center',
                                    fontFamily: 'Source Sans Pro',
                                    fontSize: '17px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px'
                                }}>{t('review_btn')}</span>
                     </ReviewButton>
                </Box>
                <Box sx={{ paddingTop:'1%', width:'87%',marginLeft: '4vh'}} >
                    <Switch>
                        { !isValidPath(location.pathname) && <Redirect to={menuItems[0].path}/>}
                        <Route path="/:path">
                            <MenuItemLayout/>
                        </Route>
                    </Switch>
                </Box>
                </div>
                


                <CustomDialogTextBox 
                    id = "inviteUserDialog"
                    open={state.isOpenedDialogInviteNewUser} 
                    title={t('dialog.heading_invite_new_user')} 
                    label={t('dialog.enter_email_address')} 
                    confirmBtnText={t('dialog.create_magic_link')}
                    confirmBtnTextAdditional={t('dialog.create_access_key')} 
                    cancelBtnText={t('dialog.button_cancel')}
                    authenticationSettingsDto = {state.integrationData.authenticationSettingsDto}/>
                <CustomDialog 
                    id = "upgradePlanDialog"
                    open={state.isOpenedDialogUpgradePlan} 
                    title={t('dialog.heading_upgrade_plan')} 
                    subtitle={t('dialog.text_upgrade_plan')} 
                    confirmBtnText= { t('dialog.button_confirm_upgrade_plan')} 
                    cancelBtnText = {t ('dialog.button_cancel')} 
                    isCentered={true} 
                    onConfirm={sendMailToSales}/>
                <CustomDialog 
                    id = "downloadReportDialog"
                    open={state.isOpenedDialogDownloadReport} 
                    title={t('dialog.heading_download_activity_report')} 
                    subtitle={t('dialog.text_download_activity_report')} 
                    confirmBtnText={t('dialog.button_download_activity_report')} 
                    cancelBtnText={t('dialog.button_cancel')} 
                    iconUrl = {downloadIcon}
                    onConfirm={downloadReport}/>  

                <CustomDialog 
                    id = "successUpgradePlanDialog"
                    open={state.isOpenedDialogSuccessUpgradePlan} 
                    onConfirm={handleCloseSuccessSendMail}
                    onCancel={handleCloseSuccessSendMail}
                    title={t('dialog.heading_sales_request_sent')} 
                    subtitle={t('dialog.text_sales_request_sent')} 
                    confirmBtnText={t('dialog.button_sales_request_sent')} 
                    iconUrl = {thumbIcon} 
                    singleButton = {true}/>
                <CustomDialog
                    id = "failUpgradePlanDialog"
                    open={state.isOpenedDialogAccountCheckFailure} 
                    onConfirm={handleCloseDialogAccountCheckFailure}
                    title={t('dialog.heading_delete_account_error')} 
                    subtitle={t('dialog.text_delete_account_error')} 
                    confirmBtnText={t('dialog.button_okay')} 
                    singleButton = {true}/>

                <CustomDialog
                    open={state.isOpenedDialogAccountAccessRevoke} 
                    onConfirm={handleCloseDialogAccountAccessRevoke}
                    title={t('dialog.delete_account_access_revoke_title')} 
                    subtitle={t('dialog.delete_account_access_revoke_text')} 
                    confirmBtnText={t('dialog.button_okay')} 
                    cancelBtnText={t('dialog.button_cancel')}
                    isCentered={true}
                /> 

                <CustomDialog 
                    id = "deleteAccountDialog"
                    open={state.isOpenedDialogDeleteAccount}
                    onConfirm={handleDialogAccountDelete}
                    title={t('dialog.heading_delete_account')}  
                    confirmBtnText={t('dialog.button_confirm_delete_account')} 
                    cancelBtnText={t('dialog.button_cancel')} 
                    isCentered={true}/>

                <CustomDialog 
                    id = "deleteDeviceDialog"
                    open={state.isOpenedDialogDeleteDevice}
                    onConfirm={handleDialogDeviceDelete}
                    title={t('dialog.delete_device_heading')}  
                    confirmBtnText={t('dialog.delete_device_confirm')} 
                    cancelBtnText={t('dialog.button_cancel')} 
                    isCentered={true}/>

                <CustomDialog 
                    open={state.isOpenedDialogDeleteUser} 
                    title={t('dialog.are_you_sure')} 
                    subtitle={t('dialog.delete_user_subtitle')}        
                    confirmBtnText={t('dialog.delete_user_confirm')} 
                    cancelBtnText={t('dialog.button_cancel')} 
                    email={state.userDataEmail}
                    isCentered={true}
                    onConfirm={handleDialogUserDelete}/> 

                
                <CustomDialog
                    id = "refreshSessionDialog"
                    open={state.isOpenedDialogRefreshSession}
                    onConfirm={refreshSession}
                    title={t('dialog.refresh_session_title')}
                    subtitle={
                        <div>
                            <Typography variant="h4" color="textPrimary">
                            {t('dialog.refresh_session_message')}
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={refreshSessionDialogProgress}
                                sx={{margin:'20px',
                                    backgroundColor:'transparent',
                                    boxShadow:'inset 0px 0px 6px #00000055',
                                    borderRadius:'7px',
                                    height:'13px'}}/>
                        </div>
                    }
                    confirmBtnText={t('dialog.refresh')}
                    singleButton = {true}
                    hideCloseButton = {true}/>
            </Container>
        </React.Fragment>
    );
};

function MenuItemLayout() {
    let { path } = useParams();
    return (
        getMenuItemByPath(`/${path}`).layout
    );
}

export default withRouter(MainLayout);
