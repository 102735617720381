import React, {useEffect} from 'react';
import { makeStyles } from "@mui/styles"
import {Grid} from '@mui/material';
import {useTranslation} from "react-i18next";
import {configManager} from "../../App";
import appTutorial from '../../video/appTutorial.mov'
import {
    ButtonSignup,
    InverseButtonSignup,
} from '../../components/CustomUI';
import arrowRight from '../../img/arrow_right.svg';
import appStoreIcon from '../../img/apple_store_badge.svg';
import googlePlayIcon from '../../img/google_play_store_badge.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import Api from "../../helpers/service";
import {log} from "../../helpers/utils";
import BasicLayout from "../BasicLayout";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        padding: 20
    },
    center: {
        maxWidth: 480,
        margin: 'auto',
        paddingBottom: 20
    },
    background: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 30,
        boxShadow: "2px 2px 5px #9E9E9E",
        maxWidth: 320,
        marginTop: 20
    },
    logo: {
        width: 135,
        float: 'left'
    },
    stepRoot: {
        borderRadius: 16,
        border: '1px solid var(--blue-primary-10, #F0F5FF)',
        padding: 56,
        display: "flex",
        flexDirection: "column",
        flex: '1 0 0',
        alignSelf: 'stretch',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
        background: 'var(--White, #FFF)',
        gap: 32
    },
    stepContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-end,',
        gap: 24,
        alignSelf: "stretch"
    },
    stepHeader: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start,',
        gap: 8,
        alignSelf: "stretch",
    },
    stepText: {
        fontFamily: 'Source Sans Pro',
        color: theme.palette.primary.main,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: 0.14
    },
    headingText: {
        fontFamily: 'Source Sans Pro',
        color: '#000',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: 0.3
    },
    stepButton: {
        borderRadius: '24px',
        display: 'flex',
        padding: '12px 16px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',

    },
    buttonText: {
        color: 'var(--White, #FFF)',
        textAlign: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px'
    },
    setupHeading: {
        color: 'black',
        textAlign: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: 32,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '40px',
        width: '1360px',
        '@media (max-width:1919px)': {
            gap: 8
        }
    },
    imgRoot: {
        flex: '1 0 0',
        alignSelf: 'center',
        borderRadius: '16px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        maxWidth: 320,
        maxHeight: 320,
        '@media (max-width:1919px)': {
            width: 170,
            height: 170,
         }
    },
    qrRoot: {
        flex: '1 0 0',
        alignSelf: 'center',
        borderRadius: '16px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        maxWidth: 320,
        maxHeight: 320,
    },
    QRcodeText: {
        color: 'var(--Black, #000)', 
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0.14px'
    },
    buttonSpan : {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch'
    },
    divRoot : {
        display: 'inline-flex',
        padding: '40px 40px 44px 40px',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--gray-1, #F7F7F7)',
        width: '100%',
        height: '100%'
    },
    firstContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: 24,
        width: '1360px',
        '@media (max-width:1919px)': {
            gap: 8
        }
    },
    logoAndBack : {
        display: 'flex',
        width: '1360px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleText : {
        color: 'var(--Black, #000)',
        width: '1360px',
        fontFamily: 'Source Sans Pro',
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '48px',
        letterSpacing: '0.36px',
        '@media (max-width:1919px)': {
            fontSize: '30px',
            lineHeight: '40px',
        }
       
    },    
    stepsContent: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 32,
        justifyContent: 'space-between'
    },
    oneStep: {
        display: 'flex',
        padding: '56',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '1 0 0',
        alignSelf: 'stretch',
        height: 680,
        '@media (max-width:1919px)': {
            height: 590
        }
    },
    thirdStepContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '32px',
        flex : '1 0 0',
        alignSelf: 'stretch'
    },
    thirdStepQR: {
        display: 'flex',
        height: '320px',
        padding: 44,
        alignItems: 'flex-start',
        gap: 4,
        alignSelf: 'stretch',
    },
    appImages: {
        display: 'flex',
        justifyContent: 'cener',
        alignItems: 'flex-start',
        gap: '32px',
        alignSelf: 'stretch',
    }

}));

export default function SamlSingup(props) {
    const {onBackBtn} = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const { t } = useTranslation();
    const steps = getSteps();
    const [url, setUrl] = React.useState("");
    const isSmallScreen = useMediaQuery('(max-width: 1919px)');
    const [qrCodeData, setQrCodeData] = React.useState('');
    const api = new Api();

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleRegistrationBackButton = () => {
        handleReset();
        onBackBtn()
    };
  

    useEffect(
        () => {
            getQr();
        },
        []
    );


    function getQr() {
        api.getQrCodeSetup().then(function (res) {
            setQrCodeData(res)
        }).catch(function (error) {
            log(error);
        })
    }

    const handleNextPrepareQr = () => {
        getQr()
        setActiveStep(2)
    }

    const AppStep = (
        <div className={classes.stepRoot} style={{
            opacity: activeStep === 0 ? 1 : 0.3, justifyContent: "space-between", gap: 32, height: isSmallScreen ? '600px' : '720px',
        }}>
            <div className={classes.stepHeader}>
                <span className={classes.stepText}>{t('setup_page.step_1')}</span>
                <span className={classes.headingText}> {t('setup_page.step_1_title')}</span>
            </div>
            <img className={classes.imgRoot} src={"/ideesaml/login/qr"} alt="QR Code" />
            <span className={classes.QRcodeText}>{t('setup_page.step_1_subtitle')}</span>
            <div className={classes.appImages}>
                <a href={configManager.iosLink} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: 132, height: 44 }} src={appStoreIcon} ></img>
                </a>
                <a href={configManager.iosLink} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: 149, height: 44 }} src={googlePlayIcon} ></img>
                </a>
            </div>
            <ButtonSignup className={classes.stepButton} style={{
                visibility: activeStep === 0 ? 'visible' : 'hidden',
                height: 'auto',
                alignSelf: 'stretch',
            }} onClick={(event => setActiveStep(1))}>
                <span className={classes.buttonText} >{t('setup_page.next_btn')}</span>
                <img style={{
                    width: '24px',
                    height: '24px'
                }} src={arrowRight}></img>
            </ButtonSignup>
        </div>
    );

    const InstructionStep = (
        <div className={classes.stepRoot} style={{ opacity: activeStep === 1 ? 1 : 0.3, alignItems: "center", height: isSmallScreen ? '600px' : '720px',
    }}>
            <div className={classes.stepHeader}>
                <span className={classes.stepText}>{t('setup_page.step_2')}</span>
                <span className={classes.headingText}>{t('setup_page.step_2_title')}</span>
            </div>
            <div style={{ width: 236, height: isSmallScreen ? '384px' : '512px', display: 'flex', justifyContent: 'center'}}>
                <video controls autoPlay loop muted style={{ width: isSmallScreen ? '160px' : '222px', height: isSmallScreen ? '366px' : '479px', flexShrink: 0, borderRadius: isSmallScreen ? '19px' :'26.441px', border: '4.808px solid #C0D5FF' }}>
                    <source src={appTutorial} type="video/mp4"></source>
                </video>
            </div>


            {activeStep === 1 &&
                <span className={classes.buttonSpan}>
                    <InverseButtonSignup className={classes.stepButton} style={{
                        flex: '1 0 0',
                    }} onClick={(event => setActiveStep(0)
                        )} >{t("back")}</InverseButtonSignup>
                    <ButtonSignup className={classes.stepButton} style={{
                        flex: '1 0 0',
                        height: 'auto',
                        minWidth: 0
                    }} onClick={(event => handleNextPrepareQr())} >{t('setup_page.next_btn')}<img style={{
                        width: '24px',
                        height: '24px'
                    }} src={arrowRight}></img> </ButtonSignup>
                </span>
            }
        </div>
    );

    const SetUpDeviceStep = (
        <div className={classes.stepRoot} style={{
            alignItems: 'flex-start',
            gap: '24px', opacity: activeStep === 2 ? 1 : 0.3,
            height: isSmallScreen ? '600px' : '720px',
        }}>
            <div className={classes.stepHeader}>
                <span className={classes.stepText}>{t('setup_page.step_3')}</span>
                <span className={classes.headingText}>{t('setup_page.step_3_title')}</span>
            </div>
            <div className={classes.thirdStepContent}>
                    <img className={classes.qrRoot} src={qrCodeData} alt="QR" style={{alignSelf: 'stretch'}} />
            </div>
        </div>
    );
    const content = (
        <div className={classes.divRoot}>
            <div className={classes.firstContent}>
                <div className={classes.setupHeading}>
                    <span className={classes.titleText} style={{whiteSpace: 'pre-wrap'}}>{t('setup_page.title')}</span>
                </div>
                <Grid item xs={12} md={12} lg={12} className={classes.stepsContent}>
                    {steps.map((label, index) => (
                        <Grid xs={4} md={4} lg={4} className={classes.oneStep}>
                            {getStepContent(index)}
                        </Grid>

                    ))}
                </Grid>
            </div>

        </div>
    );

    // Registration steps
    function getSteps() {
        return [
            t('webauthn.reg_step_1_email_title'),
            t('webauthn.reg_step_2_title'),
            t('webauthn.add_device_scan_qr')
        ];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return AppStep;
            case 1:
                return InstructionStep;

            case 2:
                return SetUpDeviceStep;

            default:
                return 'Unknown step';
        }
    }

 

    return (
        <BasicLayout content={content} isSetup={true}/>
    );
}